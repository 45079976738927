import { useLocation, useSearchParams } from "react-router-dom";

const usePath = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  return {
    pathname,
    searchParams,
    setSearchParams,
  };
};

export default usePath;
