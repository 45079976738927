import { Button, Card, Form, Input, Spin, notification } from "antd";
import { auth } from "common/lib/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

import Title from "antd/es/typography/Title";

import useAuthState from "hooks/useAuthState.hook";

import { SCREENS } from "common/types/screens";

const Landing = () => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const { setIsAuthStateLoading, isAuthStateLoading } = useAuthState({
    successScreen: SCREENS.HOME_SCREEN,
  });

  const onFinish = async (values: { email: string; password: string }) => {
    try {
      setIsAuthStateLoading(true);
      await signInWithEmailAndPassword(auth, values.email, values.password);
    } catch (error) {
      form.resetFields();
      notificationApi.error({
        message: "Invalid credentials! Try again.",
        placement: "bottomRight",
        duration: 4,
      });
    } finally {
      setIsAuthStateLoading(false);
    }
  };

  return (
    <div className="landing-page-container">
      {contextHolder}
      <div className="login-form">
        <Card bordered={false}>
          <Title level={4} type="secondary">
            Admin Login
          </Title>
          <Form
            layout="vertical"
            name="admin-login"
            onFinish={onFinish}
            form={form}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password type="password" size="middle" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isAuthStateLoading}
            >
              Login
            </Button>
          </Form>
        </Card>
      </div>
      <Spin fullscreen spinning={isAuthStateLoading} size="large" />
    </div>
  );
};

export default Landing;
