import React, { Suspense, lazy } from "react";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { Spin } from "antd";

import Landing from "screens/Landing.screen";

import { SCREENS } from "common/types/screens";
import "./styles/main.scss";
import MainWrapper from "layouts/MainWrapper.layout";

const Home = lazy(() => import("screens/Home.screen"));

const Songs = lazy(() => import("screens/songs/Songs.screen"));
const PreviewSong = lazy(() => import("screens/songs/Preview.screen"));
const CreateEditSong = lazy(
  () => import("screens/songs/CreateEditSong.screen")
);
const OutsourceSong = lazy(() => import("screens/songs/OutsourceSong.screen"));

const Contributors = lazy(
  () => import("screens/contributors/Contributors.screen")
);

const CraftSongs = lazy(() => import("screens/craft-songs/Crafts.screen"));
const ManageUsers = lazy(
  () => import("screens/manage-users/ManageUsers.screen")
);

function App() {
  return (
    <Router>
      <Suspense fallback={<Spin spinning fullscreen />}>
        <Routes>
          <Route path={SCREENS.LANDING_SCREEN} element={<Landing />} />
          <Route element={<MainWrapper />}>
            <Route path={SCREENS.HOME_SCREEN} element={<Home />} />

            {/* SONGS */}
            <Route path={SCREENS.SONGS_SCREEN} element={<Songs />} />
            <Route
              path={SCREENS.PREVIEW_SONG_SCREEN}
              element={<PreviewSong />}
            />
            <Route
              path={SCREENS.CREATE_NEW_SONG_SCREEN}
              element={<CreateEditSong />}
            />
            <Route
              path={SCREENS.EDIT_SONG_SCREEN}
              element={<CreateEditSong />}
            />
            <Route
              path={SCREENS.OUTSOURCE_SONG_SCREEN}
              element={<OutsourceSong />}
            />

            {/* MANAGE */}
            <Route path={SCREENS.CRAFT_SONGS_SCREEN} element={<CraftSongs />} />
            <Route
              path={SCREENS.MANAGE_USERS_SCREEN}
              element={<ManageUsers />}
            />

            <Route path={SCREENS.CONTRIBUTORS} element={<Contributors />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
