export enum SCREENS {
  LANDING_SCREEN = "/",
  HOME_SCREEN = "/home",

  SONGS_SCREEN = "/songs",
  PREVIEW_SONG_SCREEN = "/songs/:id",
  CREATE_NEW_SONG_SCREEN = "/songs/create-new-song",
  EDIT_SONG_SCREEN = "/songs/edit-song",
  OUTSOURCE_SONG_SCREEN = "/songs/outsource-song",

  CRAFT_SONGS_SCREEN = "/craft-songs",
  MANAGE_USERS_SCREEN = "/manage-users",

  CONTRIBUTORS = "/contributors",
}
