import React, { useMemo, useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { Avatar, Button, Layout, Menu, Space, message } from "antd";

import useDialog from "hooks/useDialog.hook";
import usePath from "hooks/usePath.hook";
import useAuthState from "hooks/useAuthState.hook";

import { SCREENS } from "common/types/screens";

import { ItemType } from "antd/es/menu/hooks/useItems";
import useAdminRole from "hooks/useAdminRole.hook";

const { Header, Content } = Layout;

const sidebarMenu: ItemType[] = [
  {
    key: SCREENS.SONGS_SCREEN,
    icon: <i className="fa-solid fa-music" />,
    label: <NavLink to={SCREENS.SONGS_SCREEN}>Songs</NavLink>,
  },
  // {
  //   key: SCREENS.CRAFT_SONGS_SCREEN,
  //   icon: <i className="fa-solid fa-wave-square"></i>,
  //   label: "Drafted-Songs",
  // },
  {
    key: SCREENS.CONTRIBUTORS,
    icon: <i className="fa-solid fa-users-line"></i>,
    label: <NavLink to={SCREENS.CONTRIBUTORS}>Contributors</NavLink>,
  },
];

const MainWrapper = () => {
  const [popupMessageAPI, popupMessageContextHolder] = message.useMessage();
  const { dialogContextHolder, showConfirmation, showMessage } = useDialog();
  const currentLocation = usePath().pathname;
  const [selectedKeys, setselectedKeys] = useState<string[]>([]);
  const { isSuperAdmin, user } = useAdminRole();

  const { isAuthenticated, signOutUser } = useAuthState({});

  useMemo(() => {
    if (currentLocation) {
      const currentKey = currentLocation.split("/")[1];
      setselectedKeys([`/${currentKey}`]);
    }
  }, [currentLocation]);

  const restrictedMenu = useMemo(() => {
    if (isSuperAdmin) {
      const isAdded = sidebarMenu.findIndex(
        (_menuItem) => _menuItem?.key === SCREENS.MANAGE_USERS_SCREEN
      );
      if (isAdded < 0) {
        sidebarMenu.push({
          key: SCREENS.MANAGE_USERS_SCREEN,
          icon: <i className="fa-solid fa-user-gear"></i>,
          label: <NavLink to={SCREENS.MANAGE_USERS_SCREEN}>Managers</NavLink>,
        });
      }
    }

    return sidebarMenu;
  }, [isSuperAdmin]);

  if (isAuthenticated) {
    return (
      <Layout className="main-wrapper">
        {popupMessageContextHolder}
        {dialogContextHolder}
        <Header
          className="header"
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Link to="/home">
            <div className="demo-logo-vertical">ChordsMaster</div>
          </Link>

          <Menu
            defaultSelectedKeys={["1"]}
            items={restrictedMenu}
            theme="dark"
            selectedKeys={selectedKeys}
            mode="horizontal"
            style={{ flex: 1, minWidth: 0, margin: "0 50px" }}
          />
          <Space>
            <Avatar>{user?.displayName?.[0]}</Avatar>
            <Button onClick={signOutUser}>Logout</Button>
          </Space>
        </Header>
        <Content className="main-container container">
          <Outlet
            context={{ popupMessageAPI, showConfirmation, showMessage }}
          />
        </Content>
      </Layout>
    );
  }

  return (
    <Layout className="main-wrapper">
      <Header
        className="header"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div className="demo-logo-vertical">ChordsMaster</div>
      </Header>
    </Layout>
  );
};

export default MainWrapper;
