import { UserInfo } from "firebase/auth";

export const USER_REDUCER = "user";
export const APP_STATE_REDUCER = "appState";
export const API_REDUCER = "api";

export type UserReducerType = {
  user?: UserInfo;
  role?: "admin" | "manager";
  isLoading: boolean;
  errors: string | null;
};

export type AppStateReducerType = {
  notifications: { title: string; body: string }[];
  errors: string[];
};

export type RootReducerType = {
  [APP_STATE_REDUCER]: AppStateReducerType;
  [USER_REDUCER]: UserReducerType;
};
