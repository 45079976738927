import { RootReducerType, USER_REDUCER } from "common/types/states";

import { useSelector } from "react-redux";

const useAdminRole = () => {
  const role = useSelector(
    (state: RootReducerType) => state[USER_REDUCER].role
  );
  const user = useSelector(
    (state: RootReducerType) => state[USER_REDUCER].user
  );

  return { isSuperAdmin: role === "admin", user };
};

export default useAdminRole;
