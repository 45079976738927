import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { findIndex, isUndefined, remove } from "lodash-es";
import { notification } from "antd";

import { auth } from "../lib/firebase";
import { printLogs } from "common/lib/util";

import { baseURL, environment, PAGE_SIZE } from "../config";

import { MutationLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryError } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { API_REDUCER } from "common/types/states";
import { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";

export const updateCachedArrayData = async (
  { queryFulfilled, dispatch }: MutationLifecycleApi<any, any, any>,
  {
    cachePath,
    name,
    args = undefined,
  }: {
    cachePath: string;
    name: string;
    args?: any;
  }
) => {
  const { data, meta } = await queryFulfilled;
  const actionName = meta.request.method;
  const payload = data[name];

  const cachefunction = (draft: any[]) => {
    if (actionName === "POST") {
      draft.unshift(payload);
    } else if (actionName === "DELETE") {
      remove(draft, (item) => item._id === data["_id"]);
    } else if (actionName === "PUT") {
      const index = findIndex(draft, { _id: payload["_id"] });
      draft.splice(index, 1, payload);
    }
  };

  try {
    dispatch(
      apiEntry.util.updateQueryData(
        cachePath as never,
        args as never,
        cachefunction
      )
    );
  } catch (error) {
    printLogs("CACHING ERROR ", error, true);
  }
};

export const updateCachedTableListData = async (
  dispatch: ThunkDispatch<any, any, UnknownAction>,
  {
    cachePath,
    cacheItem,
    cacheKeyName,
    actionName,
  }: {
    cachePath: string;
    cacheItem: any;
    cacheKeyName: string;
    actionName?: string;
  }
) => {
  const cachefunction = (draft: any) => {
    if (actionName === "POST") {
      let totalOffset = 1;

      if (Array.isArray(cacheItem)) {
        totalOffset = cacheItem.length;
        draft[cacheKeyName].unshift(...cacheItem);
      } else {
        draft[cacheKeyName].unshift(cacheItem);
      }

      if (!isUndefined(draft?.pagination?.total)) {
        draft.pagination.total = draft.pagination.total + totalOffset;
      }
    } else if (actionName === "DELETE") {
      if (cacheItem["_id"]) {
        remove(
          draft[cacheKeyName],
          (item: any) => item._id === cacheItem["_id"]
        );

        if (!isUndefined(draft?.pagination?.total)) {
          draft.pagination.total = draft.pagination.total - 1;
        }
      }
    } else if (actionName === "PUT") {
      const index = findIndex(draft[cacheKeyName], { _id: cacheItem["_id"] });
      draft[cacheKeyName].splice(index, 1, cacheItem);
    }
  };

  try {
    dispatch(
      apiEntry.util.updateQueryData(
        cachePath as never,
        { pageSize: PAGE_SIZE } as never,
        cachefunction
      )
    );
  } catch (error) {
    printLogs("CACHING ERROR ", error, true);
  }
};

export const updateSingleItemData = async (
  dispatch: ThunkDispatch<any, any, UnknownAction>,
  {
    cachePath,
    id,
    cacheItem,
  }: {
    cachePath: string;
    id: string;
    cacheItem: any;
  }
) => {
  try {
    dispatch(
      apiEntry.util.upsertQueryData(
        cachePath as never,
        id as never,
        cacheItem as never
      )
    );
  } catch (error) {
    printLogs("CACHING ERROR ", error, true);
  }
};

const getToken = async (): Promise<string> => {
  try {
    let localToken = localStorage.getItem("access_token");

    if (!localToken) {
      localToken = (await auth.currentUser?.getIdToken(true)) || null;
      if (localToken) {
        localStorage.setItem("access_token", localToken);
      }
    }

    if (localToken) {
      return localToken;
    } else {
      throw Error("Token not found");
    }
  } catch (error) {
    return "";
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: async (headers) => {
    const token = await getToken();
    headers.set("Authorization", token);
    headers.set("Environment", environment);
    headers.set("Access-Control-Allow-Origin", "*");
  },
});

const baseQueryWithIntercept = retry(
  async (args: any, api: any, extraOptions: any) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 403) {
      try {
        const newToken = await auth.currentUser?.getIdToken(true);
        if (newToken) {
          localStorage.setItem("access_token", newToken);
        }
      } catch (error) {}
    }

    return result;
  },
  {
    retryCondition: (
      error: BaseQueryError<any>,
      baseQueryArgs,
      { attempt }
    ) => {
      if (error.status === 403 && attempt < 2) {
        return true;
      }

      if (error?.data) {
        const errorMessage = (error.data as any)?.error?.message || error.data;
        notification.error({
          description: errorMessage || "API Failed!",
          message: "Error",
          role: "alert",
          placement: "bottomRight",
          duration: 3,
        });
      }

      return false;
    },
  }
);

export const apiEntry = createApi({
  reducerPath: API_REDUCER,
  tagTypes: ["contributors", "songs", "managers"],
  baseQuery: baseQueryWithIntercept,
  endpoints: () => ({}),
});
