import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserReducerType } from "../types/states";
import { USER_REDUCER } from "../types/states";
import { UserClaimsType } from "common/types";
import { UserInfo } from "firebase/auth";

const INITIAL_STATE: UserReducerType = {
  role: undefined,
  isLoading: false,
  errors: null,
};

export const userSlice = createSlice({
  name: USER_REDUCER,
  initialState: INITIAL_STATE,
  reducers: {
    setUserClaims: (
      state: UserReducerType,
      action: PayloadAction<UserClaimsType>
    ) => {
      state.role = action.payload?.role;
    },
    setAuthUser: (state: UserReducerType, action: PayloadAction<UserInfo>) => {
      state.user = action.payload;
    },
  },
});

export const { setUserClaims, setAuthUser } = userSlice.actions;

export default userSlice.reducer;
