import { combineSlices } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./common/reducers/user.slice";
import appStateReducer from "./common/reducers/appState.slice";

import {
  API_REDUCER,
  APP_STATE_REDUCER,
  USER_REDUCER,
} from "./common/types/states";
import { apiEntry } from "./common/api";

export default combineSlices({
  [APP_STATE_REDUCER]: persistReducer(
    { key: APP_STATE_REDUCER, storage },
    appStateReducer
  ),
  [USER_REDUCER]: persistReducer({ key: USER_REDUCER, storage }, userReducer),
  [API_REDUCER]: persistReducer(
    { key: API_REDUCER, storage },
    apiEntry.reducer
  ),
});
