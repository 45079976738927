import { Modal, ModalFuncProps, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ConfirmationType, MessageType } from "common/types/system";

const useDialog = () => {
  const [modal, dialogContextHolder] = Modal.useModal();

  const onSetConfirmation = (payload: ConfirmationType) => {
    modal.confirm({
      title: payload.title,
      content: <Typography.Text>{payload?.message}</Typography.Text>,
      okText: "Confirm",
      cancelText: "Cancel",
      onCancel: () => {
        payload?.onCancel?.();
      },
      onOk: () => {
        payload.onConfirm();
      },
      icon: <ExclamationCircleOutlined />,
    });
  };

  const onSetMessage = ({
    title,
    message,
    callback,
    confirmText,
    type,
    width,
  }: MessageType) => {
    const callbackFun = () => {
      if (callback) {
        callback();
      }
    };

    const props: ModalFuncProps = {
      title: title,
      content:
        typeof message === "string" ? (
          <Typography.Text>{message}</Typography.Text>
        ) : (
          message
        ),
      okText: confirmText || "Ok",
      onOk: callbackFun,
      width,
    };

    if (type === "info") {
      modal.info(props);
    } else if (type === "success") {
      modal.success(props);
    } else {
      modal.warning(props);
    }
  };

  return {
    dialogContextHolder,
    showConfirmation: onSetConfirmation,
    showMessage: onSetMessage,
  };
};

export default useDialog;
