import { deleteObject, ref } from "firebase/storage";
import { storage } from "./firebase";
import { STORAGE_PATHS } from "common/types/firebase";
import { environment } from "common/config";

export const printLogs = (task: string, log?: any, error?: boolean) => {
  if (window.location.host.includes("localhost")) {
    if (error) {
      console.error(task, log);
    } else {
      console.log(task, log);
    }
  }
};

export const getBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getFileExtension = (path: string) => {
  try {
    const urlPath = new URL(path);
    path = urlPath.pathname;
  } catch (error) {}

  const splitArray = path.split(".");

  return `.${splitArray[splitArray.length - 1]}`;
};

export const deleteFileFromStorage = async (
  path: STORAGE_PATHS,
  fileId: string,
  url: string
) => {
  try {
    await deleteObject(
      ref(storage, `/${environment}${path}${fileId}${getFileExtension(url)}`)
    );
  } catch (error) {
    throw error;
  }
};
