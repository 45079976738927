import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { AppStateReducerType } from "types/states";
import { APP_STATE_REDUCER } from "types/states";

const INITIAL_STATE: AppStateReducerType = {
  notifications: [],
  errors: [],
};

export const appStateSlice = createSlice({
  name: APP_STATE_REDUCER,
  initialState: INITIAL_STATE,
  reducers: {
    setAppNotifications: (
      state: AppStateReducerType,
      action: PayloadAction<AppStateReducerType["notifications"]>
    ) => {
      state.notifications = action.payload;
    },
    setAppErrors: (
      state: AppStateReducerType,
      action: PayloadAction<string>
    ) => {
      state.errors = [...state.errors, action.payload];
    },
  },
});

export const { setAppNotifications, setAppErrors } = appStateSlice.actions;

export default appStateSlice.reducer;
