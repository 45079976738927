import { useCallback, useEffect, useState } from "react";
import { auth } from "common/lib/firebase";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setAuthUser, setUserClaims } from "common/reducers/user.slice";

import { UserClaimsType } from "common/types";
import { SCREENS } from "common/types/screens";

const useAuthState = ({ successScreen }: { successScreen?: SCREENS }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAuthStateLoading, setIsAuthStateLoading] = useState<boolean>(true);
  const [isAuthenticated, setisAuthenticated] = useState<boolean>(false);

  const signOutUser = useCallback(() => {
    setIsAuthStateLoading(false);
    auth?.signOut();
    dispatch(setUserClaims({ role: undefined }));
    localStorage.clear();
    navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    const authSubscribe = auth.onAuthStateChanged(async (user) => {
      if (user && !user.isAnonymous) {
        const idTokenResult = await user?.getIdTokenResult();
        setIsAuthStateLoading(false);
        const userRole = idTokenResult?.claims?.role as UserClaimsType["role"];

        if (userRole === "admin" || userRole === "manager") {
          setisAuthenticated(true);
          dispatch(setUserClaims({ role: userRole }));
          dispatch(
            setAuthUser({
              displayName: user.displayName,
              email: user.email,
              phoneNumber: user.phoneNumber,
              photoURL: user.photoURL,
              providerId: user.providerId,
              uid: user.uid,
            })
          );
          if (successScreen) {
            navigate(SCREENS.HOME_SCREEN);
          }
        } else {
          signOutUser();
        }
      } else {
        signOutUser();
      }
    });

    return () => {
      authSubscribe();
    };
  }, [dispatch, navigate, signOutUser, successScreen]);

  return {
    isAuthenticated,
    isAuthStateLoading,
    setIsAuthStateLoading,
    navigate,
    signOutUser,
  };
};

export default useAuthState;
